/*
 *
 * Yoan Asdrubal Quintana Ramirez.
 *  4/4/2019
 *
 */


// Nombres de datasources para ser referenciados desde cualquier parte de la app

import { environment } from "../../../environments/environment";
import { paramsTypeModel } from "../model/paramsTypeModel";
// @ts-ignore
import { DatasourceConfig } from "./datasource.config";

export const DATASOURCE_NAMES = {
	MODEL_ACCIDENT_RATE: "AccidentRate",
	MODEL_CLAIMS: "Claims",
	MODEL_REINSURANCE_RATE: "ReinsuranceRate",
	MODEL_PARAM_MODEL: "ParamsModel",
	MODEL_PARAM_TYPE_MODEL: "ParamsTypeModel",
	MODEL_REINSURANCE_REMUNERACION_MODEL: "ReinsuranceRemuneracionModel",
	MODEL_REINSURANCE_REINSUARENCER_MODEL: "ReinsurancerModel",
	MODEL_RISK_COVERAGE_RATE: "RiskCoverageRate",
	MODEL_COMPLEMENTARY_COVERAGE: "ComplementaryCoverage",
	MODEL_MORTALITY_TABLE: "MortalityTable",
	MODEL_QUOTATION_MODEL: "QuotationModel",
	MODEL_TECHNICAL_SURCHARGE: "TechnicalSurcharge",
	MODEL_USER: "UserModel",
	MODEL_PREMIUM: "PremiumModel",
	MODEL_REJECT_REASONS: "RejectReasons"
};
export const moduleObject = environment.paramsConfig.module;

export const DATASOURCE_CONFIG: DatasourceConfig = {
	datasources: {
		AccidentRate: {
			model: DATASOURCE_NAMES.MODEL_ACCIDENT_RATE,
			url: environment.api.stagingProxy,
			api: {
				GET_RESUM: { method: "GET", url: "/accident-rate-vl/{codeContractor}/resume", name: "Resumen de siniestros" },
				GET_DETAIL: { method: "GET", url: "/accident-rate-vl/{codeContractor}", name: "Detalle de primas" },
				GET_EXCEL: { method: "GET", url: "/accident-rate-vl/{codeContractor}/xlsx", name: "Obtener Detalle de primas" },
			}
		},
		Claims: {
			model: DATASOURCE_NAMES.MODEL_CLAIMS,
			url: environment.api.stagingProxy,
			api: {
				GET_DETAIL: { method: "GET", url: "/accident-rate/claim-list/{codeContractor}", name: "Detalle de Siniestros" },
				GET_EXCEL: { method: "GET", url: "/accident-rate/claim-list/{codeContractor}/xls", name: "Obtener Detalle de Siniestros" },
			}
		},
		ReinsuranceRate: {
			model: DATASOURCE_NAMES.MODEL_REINSURANCE_RATE,
			url: environment.api.quotation,
			api: {
				LIST: { method: "GET", url: "/reinsurance-rate/{branch}/{product}", name: "Listado de reaseguros" },
				GET: { method: "GET", url: "/reinsurance-rate/{id}", name: "Obtener reaseguro" },
				POST: { method: "POST", url: "/reinsurance-rate", name: "Adicionar reaseguro" },
				PUT: { method: "PUT", url: "/reinsurance-rate", name: "Modificar reaseguro" },
				DELETE: { method: "DELETE", url: "/reinsurance-rate/{id}", name: "Eliminar reaseguro" }
			}
		},
		ReinsuranceRemuneracionModel: {
			model: DATASOURCE_NAMES.MODEL_REINSURANCE_REMUNERACION_MODEL,
			url: environment.api.stagingProxy,
			api: {
				LIST: { method: "GET", url: "/remuneration", name: "Listado de remuneraciones" }
			},
			items: [
				{
					id: "tope",
					description: "Tasa tope"
				},
				{
					id: "tope_exceso",
					description: "Tasa exceso tope"
				}
			]
		},
		ReinsurancerModel: {
			model: DATASOURCE_NAMES.MODEL_REINSURANCE_REINSUARENCER_MODEL,
			url: environment.api.stagingProxy,
			api: {
				LIST: { method: "GET", url: "/reinsurancer", name: "Listado de reaseguradoras" }
			}
		},
		RiskCoverageRate: {
			model: DATASOURCE_NAMES.MODEL_RISK_COVERAGE_RATE,
			url: environment.api.sctr_quotator,
			api: {
				LIST: {
					method: "GET",
					url: `/risk-coverage-rate/{branch}/{product}/${moduleObject.riskCoverageRate.code}`,
					name: "Listado de tasa de riesgo por cobertura"
				},
				LIST_INACTIVE: {
					method: "GET",
					url: `/risk-coverage-rate/deprecated/{branch}/{product}/${moduleObject.riskCoverageRate.code}`,
					name: "Listado de tasa de riesgo por cobertura inactivo"
				},
				POST: { method: "POST", url: "/risk-coverage-rate/", name: "Adicionar tasa de riesgo por cobertura" },
				PUT: { method: "PUT", url: "/risk-coverage-rate/", name: "Modificar tasa de riesgo por cobertura" },
				DELETE: { method: "DELETE", url: "/risk-coverage-rate/", name: "Eliminar tasa de riesgo por cobertura" }
			}
		},
		ComplementaryCoverage: {
			model: DATASOURCE_NAMES.MODEL_COMPLEMENTARY_COVERAGE,
			url: environment.api.stagingProxy,
			api: {
				LIST: {
					method: "GET",
					url: `/risk-coverage`,
					name: "Listado de coberturas complementarias"
				},
				GET_DERIVATION_TECNICA: {
					method: "GET",
					url: '/risk-coverage/unissued-derived',
					name: 'Listado de coberturas con derivación a técnica'
				},
				GET_NOT_DERIVATION_TECNICA: {
					method: "GET",
					url: '/risk-coverage/unissued-underived',
					name: 'Listado de coberturas sin derivación a técnica'
				},
				GET_POLICY: {
					method: "GET",
					url: '/risk-coverage/issued-policy',
					name: 'Listado de coberturas de pólizas emitidas'
				}
			}
		},
		ParamsModel: {
			model: DATASOURCE_NAMES.MODEL_PARAM_MODEL,
			url: environment.api.quotation,
			api: {
				LIST: {method: "GET", url: "/parameter/{branch}/{product}", name: "Listado de parámetros."},
				GET: {method: "GET", url: "/parameter/{branch}/{product}/{id}", name: "Obtener parámetro."},
				POST: {method: "POST", url: "/parameter", name: "Adicionar parámetro."},
				PUT: {method: "PUT", url: "/parameter", name: "Modificar parámetro."},
				DELETE: {method: "DELETE", url: "/parameter", name: "Eliminar parámetro."},
				CORE_PARAM: {method: "GET", url: environment.api.stagingProxy + "/parameter/VIDA_LEY_TOPE", name: "Obtener parámetro TOPE.", skip: true},
				GET_BY_TYPE: {method: "GET", url: "/parameter/type/{branch}/{product}/{id}", name: "Obtener parámetros por tipo."}
			}
		},
		ParamsTypeModel: {
			items: paramsTypeModel
		},
		MortalityTable: {
			model: DATASOURCE_NAMES.MODEL_MORTALITY_TABLE,
			url: environment.api.sctr_quotator,
			api: {
				GET: {method: "GET", url: "/mortalityTable", name: "Listar tablas de mortalidad"},
				POST: {method: "POST", url: "/mortalityTable", name: "Adicionar tabla de mortalidad"},
				PUT: {method: "PUT", url: "/mortalityTable", name: "Modificar tabla de mortalidad"},
				DELETE: {method: "DELETE", url: "/mortalityTable/{id}", name: "Eliminar tabla de mortalidad"},
				GET_V: {
					method: "GET",
					url: "/mortalityTable/{id}/{version}",
					name: "Obtener una versión de la tabla de mortalidad"
				},
				LIST_V: {
					method: "GET",
					url: "/mortalityTable/{id}",
					name: "Listar versiones de Tablas de mortalidad"
				}
			}
		},
		TechnicalSurcharge: {
			model: DATASOURCE_NAMES.MODEL_TECHNICAL_SURCHARGE,
			url: environment.api.sctr_quotator,
			api: {
				LIST: { method: "GET", url: "/technical-surcharge/{branch}/{product}", name: "Listado de recargos técnicos" },
				POST: { method: "POST", url: "/technical-surcharge/", name: "Adicionar recargos técnico" },
				PUT: { method: "PUT", url: "/technical-surcharge/", name: "Modificar recargos técnico" },
				DELETE: { method: "DELETE", url: "/technical-surcharge/", name: "Eliminar recargos técnico" }
			}
		},
		QuotationModel: {
			model: DATASOURCE_NAMES.MODEL_PARAM_MODEL,
			url: environment.api.sctr_quotator,
			api: {
				LIST: {method: "GET", url: "/quotation-request", name: "Listar Cotización."},
				GET: {method: "GET", url: "/quotation-request/{branch}/{product}/{number}", name: "Obtener datos de cotización."},
				GET_DETAIL: {method: "GET", url: "/quotation-request/detail/{id}", name: "Obtener detalles de cotización."},
				GET_FILES: {method: "GET", url: "/quotation-request/attachment-list/{number}/{quotationType}", name: "Ficheros de Solicitud."},
				CREATE_FILE: {method: "POST", url: "/quotation-request/attachment/create", name: "Crear Ficheros de Solicitud."},
				GET_FILE: {method: "POST", url: "/quotation-request/attachment/get", name: "Obtener Ficheros de Solicitud."},
				DELETE_FILE: {method: "POST", url: "/quotation-request/attachment/remove", name: "Eliminar Ficheros de Solicitud."},
				UPDATE_FILE_COMMENT: {method: "PUT", url: "/quotation-request/attachment-comment", name: "Modificar comentario."},
				GET_QUOTATION_VERSION: {method: "POST", url: "/quotation/get", name: "Obtener versión de cotización."},
				CREATE_QUOTATION_VERSION: {method: "POST", url: "/quotation/create", name: "Crear versión de cotización."},
				GET_QUOTATION_VERSIONS: {method: "GET", url: "/quotation/{id}", name: "Obtener versiones de cotización"},
				ACCIDENT_RATE_CORE: {
					method: "GET",
					url: `${environment.api.stagingProxy}/accident-rate/{clientId}`,
					skip: true,
					name: "Siniestralidad de clientes antiguos"
				},
				ACCIDENT_RATE_CORE_XLS: {
					method: "GET",
					url: `${environment.api.stagingProxy}/accident-rate/{clientId}/xlsx`,
					skip: true,
					name: "Siniestralidad de clientes antiguos"
				},
				ACCIDENT_RATE_LIST: { method: "GET", url: "/quotation/{number}", name: "Obtener versiones de cotización" },
				RESEND: {method: "POST", url:"/quotation-resend/", name: "Reenviar cotización"}
			}
		},
		UserModel: {
			model: DATASOURCE_NAMES.MODEL_USER,
			url: environment.api.stagingProxy,
			api: {
				GET: { method: "GET", url: "/user/{userNumber}", name: "Obtener el nombre del usuario dado el código" }
			}
		},
		PremiumModel: {
			model: DATASOURCE_NAMES.MODEL_PREMIUM,
			url: environment.api.stagingProxy,
			api: {
				GET: { method: "GET", url: "/minimum-premium/{quotationNumber}", name: "Obtener la prima mínima" }
			}
		},
		RejectReasons: {
			model: DATASOURCE_NAMES.MODEL_REJECT_REASONS,
			url: environment.api.sctr_quotator,
			api: {
				GET: {method: "GET", url: "/reject-reasons/", name: "Obtener motivos de rechazo"}
			}
		}
	}
};
