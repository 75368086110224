import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { Worker } from "../../../../core/model/quotation/quotation.model";
import { calculoEdad } from "../../../../core/util/date.util";

const moment = extendMoment(Moment);

/**
 *
 * @param initDate fecha inicio
 * @param endDate fecha fin
 * Devuele la cantidad de meses de diferencia entre las dos fechas
 */
function calculateDiff(initDate: any, endDate: any): number {
	const endDateResult = moment(new Date(endDate)).add(1, "d"),
		range = moment.range(initDate, endDateResult),
		month = range.diff("months", true);
	if (month === 24 || month > 23) {
		return 24;
	}
	if (month === 23 || month > 22) {
		return 23;
	}
	if (month === 22 || month > 21) {
		return 22;
	}
	if (month === 21 || month > 20) {
		return 21;
	}
	if (month === 20 || month > 19) {
		return 20;
	}
	if (month === 19 || month > 18) {
		return 19;
	}
	if (month === 18 || month > 17) {
		return 18;
	}
	if (month === 17 || month > 16) {
		return 17;
	}
	if (month === 16 || month > 15) {
		return 16;
	}
	if (month === 15 || month > 14) {
		return 15;
	}
	if (month === 14 || month > 13) {
		return 14;
	}
	if (month === 13 || month > 12) {
		return 13;
	}
	if (month === 12 || month > 11) {
		return 12;
	}
	if (month === 11 || month > 10) {
		return 11;
	}
	if (month === 10 || month > 9) {
		return 10;
	}
	if (month === 9 || month > 8) {
		return 9;
	}
	if (month === 8 || month > 7) {
		return 8;
	}
	if (month === 7 || month > 6) {
		return 7;
	}
	if (month === 6 || month > 5) {
		return 6;
	}
	if (month === 5 || month > 4) {
		return 5;
	}
	if (month === 4 || month > 3) {
		return 4;
	}
	if (month === 3 || month > 2) {
		return 3;
	}
	if (month === 2 || month > 1) {
		return 2;
	}
	if (month === 1 || month > 0) {
		return 1;
	}
}

function getValidityByMonth(month: number): string {
	if (month === 24 || month > 23) {
		return "Bianual";
	}
	if (month === 23 || month > 22) {
		return "23 Meses";
	}
	if (month === 22 || month > 21) {
		return "22 Meses";
	}
	if (month === 21 || month > 20) {
		return "21 Meses";
	}
	if (month === 20 || month > 19) {
		return "20 Meses";
	}
	if (month === 19 || month > 18) {
		return "19 Meses";
	}
	if (month === 18 || month > 17) {
		return "18 Meses";
	}
	if (month === 17 || month > 16) {
		return "17 Meses";
	}
	if (month === 16 || month > 15) {
		return "16 Meses";
	}
	if (month === 15 || month > 14) {
		return "15 Meses";
	}
	if (month === 14 || month > 13) {
		return "14 Meses";
	}
	if (month === 13 || month > 12) {
		return "13 Meses";
	}
	if (month === 12 || month > 11) {
		return "Anual";
	}
	if (month === 11 || month > 10) {
		return "11 Meses";
	}
	if (month === 10 || month > 9) {
		return "10 Meses";
	}
	if (month === 9 || month > 8) {
		return "9 Meses";
	}
	if (month === 8 || month > 7) {
		return "8 Meses";
	}
	if (month === 7 || month > 6) {
		return "7 Meses";
	}
	if (month === 6 || month > 5) {
		return "Semestral";
	}
	if (month === 5 || month > 4) {
		return "5 Meses";
	}
	if (month === 4 || month > 3) {
		return "Cuatrimestral";
	}
	if (month === 3 || month > 2) {
		return "Trimestral";
	}
	if (month === 2 || month > 1) {
		return "Bimestral";
	}
	if (month === 1 || month > 0) {
		return "Mensual";
	}

	return "";
}

function addFrequency(initDate: any, frequency: any): Date {
	const endDateResult = moment(new Date(initDate)).add(frequency, "months");
	return (endDateResult.subtract(1, "d")).toDate();
}

function cantMonthsRemainingEndorsement(initDate: any, endDate: any, endoseDate: any, frequencyPayment: number) {
	const validity = calculateDiff(initDate, endDate);
	const cantPayments = validity / frequencyPayment;
	let initDateTemp = initDate;

	for (let i = 0; i <= cantPayments; i++) {
		const endTemp = moment(addFrequency(initDateTemp, frequencyPayment));
		if (moment(new Date(endoseDate)).isBetween(moment(new Date(initDateTemp)), endTemp, "milliseconds", "[]")) {
			return calculateDiff(endoseDate, endTemp);
		}
		initDateTemp = endTemp.add(1, "d");
	}
}

/**
 *
 * @param items arregle de trabajadores
 * @param workerType tipo de trabajador
 * @param tope el valor maximo de salario establecido en el core
 * @param checkTop se utiliza para saber si hay que calcular el exceso del tope o no
 * Devuelve la edad ponderada de la cantidad de trabajadores de ese tipo
 */
function cantTypeWorkerAgeDown65(items: Worker[], workerType: any, tope: number, checkTop: boolean = false): number {

	const workers: Worker[] = checkTop ?
		items.filter(item => {
			return item.workerType.code_core === workerType && item.salary > tope;
		}) :
		items.filter(item => {
			return item.workerType.code_core === workerType;
		});
	let sumAges = 0;
	let ponderAge = 0;
	workers.forEach(value => {
		sumAges += calculoEdad(value.birthDate);
	});
	workers.forEach(value => {
		const age = calculoEdad(value.birthDate);
		ponderAge += (age / sumAges) * (age);
	});
	return Math.round(ponderAge);
}

/**
 *
 * @param items arreglo de trabajadores
 * @param workerType tipo de trabajador
 * @param tope el valor maximo de salario establecido en el core
 * @param checkTop se utiliza para saber si hay que calcular el exceso del tope o no
 * Devuelve el salario ponderado de los trabajadores masculinos de un tipo de trabajador
 */
function cantMaleWorkerP(items: Worker[], workerType: any, tope: number, checkTop: boolean = false): number {
	let salaryMale = 0, salaryTotal = 0;
	if (checkTop) {
		items.filter(item => item.gender.toUpperCase() === "M" && item.workerType.code_core === workerType && item.salary > tope).forEach(item => {
				salaryMale += (item.salary - tope);
			}
		);
		items.filter(item => item.workerType.code_core === workerType && item.salary > tope).forEach(item => {
			salaryTotal += item.salary - tope;
		});
		return Math.round((salaryMale / salaryTotal) * 100);
	} else {
		items.filter(item => item.gender.toUpperCase() === "M" && item.workerType.code_core === workerType).forEach(item => {
				salaryMale += (item.salary > tope) ? tope : item.salary;
			}
		);
		items.filter(item => item.workerType.code_core === workerType).forEach(item => {
			salaryTotal += (item.salary > tope) ? tope : item.salary;
		});
		return Math.round((salaryMale / salaryTotal) * 100);
	}
}

function aplicateFormat(val: number, dec: number = 2, parseToNumber: boolean = true): any {
	const res = (typeof val === 'string') ? (+val)?.toFixed(dec) : val?.toFixed(dec);
	return parseToNumber ? parseFloat(res) : res;
}

function getValuePercentByType(type: string): number {
	return type === "‰" ? 1000 : 100;
}

function convertToThousands(value: any): string {
	let valueFixed: string = "";
	if (Number.isInteger(value) && Number.isFinite(value) && !Number.isNaN(value)) {
		valueFixed = value.toFixed(2);
	} else {
		if (value.toString().includes(".")) {
			valueFixed = value.toString();
		} else {
			valueFixed = (parseInt(value, 10)).toFixed(2);
		}
	}
	if (valueFixed !== "") {
		const symbol: string = valueFixed[0];
		const restSymbol: boolean = ["+", "-"].includes(symbol);
		if (restSymbol) {
			valueFixed = valueFixed.replace(symbol, "");
		}
		const cad: string[] = valueFixed.split(".");
		const other = cad[0];
		let resul: string = "";
		let aux: string = "";
		for (let i = cad[0].length - 1; i >= 0; i--) {
			aux = other[i] + aux;
			if (aux.length === 3) {
				resul = "," + aux + resul;
				aux = "";
			}

			if (i < 2 && aux.length === 0) {
				resul = (other.substr(0, i)).concat(resul, ".", cad[1]);
				break;
			}
			if (i < 2) {
				resul = (other.substr(0, i)).concat(aux, resul, ".", cad[1]);
				break;
			}
		}
		return (restSymbol) ? symbol.concat(resul) : resul;
	} else {
		return "0.00";
	}
}

function concatSymbolPercent(value: any) {
	return (!!value) ? value.toString().concat("%") : "";
}

function concatSymbolCoin(value: any) {
	return (!!value || value === 0) ? "S/".concat(value) : "";
}

/**
 * Función para revisar que el valor pasado por parámetro se un número válido, en caso de no ser un número retorna 0
 * @param value parámetro a comprobar
 */
function verifySafeNumberReturn(value: any): number {
	return (isFinite(value) && !isNaN(value) && !!value) ? value : 0;
}

function isLeapYear(dateValue: any): boolean {
	return moment([dateValue]).isLeapYear();
}

function getDateTypeMoment(dateValue: any, format: string = "") {
	if (!!format) {
		return moment(dateValue, format).utc();
	} else {
		return moment(new Date(dateValue)).utc();
	}
}

function calculateDiffMonths(initDate: any, endDate: any): number {
	const endDateResult = moment(new Date(endDate)).add(1, "d");
	const range = moment.range(initDate, endDateResult);
	const month = range.diff("months", true);
	const cadena = month.toString();
	const cadenaSplit = cadena.split(".");
	if (cadenaSplit.length > 1) {
		return parseFloat(cadenaSplit[0]) + 1;
	} else {
		return parseFloat(cadena);
	}
}

function compareDateSort(date1, date2) {
	const date1Moment = moment(date1, "DD-MM-YYYY");
	const date2Moment = moment(date2, "DD-MM-YYYY");
	return date1Moment.isSameOrBefore(date2Moment) ? -1 : 1;
}

export {
	calculateDiff,
	cantTypeWorkerAgeDown65,
	cantMaleWorkerP,
	aplicateFormat,
	getValuePercentByType,
	convertToThousands,
	concatSymbolPercent,
	concatSymbolCoin,
	verifySafeNumberReturn,
	getValidityByMonth,
	addFrequency,
	cantMonthsRemainingEndorsement,
	isLeapYear,
	getDateTypeMoment,
	calculateDiffMonths,
	compareDateSort
};
