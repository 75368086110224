// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	keyCloakUrl: "https://protecta.devmente.io:8443/auth",
	soatBaseApiUrl: "protecta.devmente.io:8443/soat",
	sctrBaseApiUrl: "protecta.devmente.io:8443/kuntur",
	integrationApiUrl: "protecta.devmente.io:8443",
	api: {
		quotation: 'https://api.sctr.dev.protectasecuritycloud.pe',
		stagingProxy: 'https://api.sctr.dev.protectasecuritycloud.pe/integration',
		sctr_quotator: 'https://api.sctr.dev.protectasecuritycloud.pe',
		protectaApiIntegration: 'https://protecta.devmente.io:8443/dev/api/integration'
	},
	paramsConfig: {
		branch: {
			code: 73,
			description: 'Vida'
		},
		product: {
			code: 1,
			description: 'SCTR'
		},
		module: {
			/**
			 * Info: Agregar por cada componente el modulo al que pertenece
			 */
			riskCoverageRate: {
				code: 7,
				description: 'Plan Maestro'
			},
			hannoverTable: {
				code: 666,
				description: 'Tabla de Mortalidad'
			},
			complementaryCoverages: {
				law: [7, 11, 15],
				basic: 12,
				full: 9,
				special: 10,
				master: 22
			}
		}
	},
	moduleConfig: {
		params: 'Cotizador'
	},
	aws: {
		aws_project_region: "us-east-1",
		aws_cognito_identity_pool_id: "us-east-1:594902652773",
		aws_cognito_region: "us-east-1",
		aws_user_pools_id: 'us-east-1_UU9Z6a0n3',
		aws_user_pools_web_client_id: 'tcq53h177im86qdnodmalvfcl',
		aws_appsync_graphqlEndpoint: "https://m6mpj7ma5baenoq7x5iconkmmq.appsync-api.us-east-1.amazonaws.com/graphql",
		aws_appsync_graphqlCoreEndpoint: "https://api-coreintegration.tcdg.qas.protectasecuritycloud.pe/graphql",
		cookieStorage: {
			domain: ".dev.protectasecuritycloud.pe",
			path: "/",
			secure: false
		},
		oauth: {}
	},
	aws_authentication: {
		quotation_graphql_authentication_value: "da2-fngcbhyfl5hknbpwleheonmxrm",
		core_graphql_authentication_value: 'da2-hpx4kyowynainmgnd3zlynpwdu'
	},
	login: "https://login.core.dev.protectasecuritycloud.pe/#/auth/sign-in",
	versioncheckurl: "https://cotizador.sctr.dev.protectasecuritycloud.pe/version.json"
};
